<template>
  <div class="calculate-chat">
    <div class="title">
      <span>정산 문의</span>
    </div>
    <div class="wrapper">
      <div class="comments thin-scrollbar" ref="chatRef">
        <button class="btn" @click="load(false, true)" v-if="state.chat.list.length < state.chat.total" :disabled="state.chat.more || !state.chat.loaded">
          <i class="fa fa-arrow-up"></i>
          <span>더보기</span>
        </button>
        <div class="comment admin auto"
             v-if="state.chat.loaded && state.chat.pageable.size && (Math.ceil(state.chat.total / state.chat.pageable.size) - 1 === state.chat.pageable.page || Math.ceil(state.chat.total / state.chat.pageable.size) - 1 <= 0)">
          <span class="img" :style="{backgroundImage: 'url(/assets/img/common.logo.o.svg)'}"></span>
          <div class="content">
            <div class="top">
              <div class="text">
                <span>채팅을 통한 정산 문의 서비스입니다.</span>
                <span>답변은 영업일 기준 1~2일이 소요되며 순서대로 진행됩니다.</span>
                <span>정산이 마감되면 채팅 문의도 종료됩니다.</span>
                <button class="btn btn-block" @click="open()">자주 묻는 질문 보기</button>
              </div>
            </div>
          </div>
        </div>
        <div class="comment" v-for="(c, idx) in state.chat.list" :key="idx" :class="c.memberAuth.includes('ADMIN') ? 'admin' : 'maker'">
          <Anchor :href="`/channel/${c.createId}?tabs=projects`" v-if="!c.memberAuth.includes('ADMIN')">
            <MemberPicture :memberSeq="c.createId.toString()"/>
          </Anchor>
          <span class="img" :style="{backgroundImage: 'url(/assets/img/common.logo.o.svg)'}" v-else></span>
          <div class="content">
            <div class="top">
              <div class="text">
                <span>{{ c.content }}</span>
              </div>
              <div class="side" v-if="c.createId === Number($store.state.account.memberSeq)">
                <span @click="remove(c.id)" title="삭제하기"><i class="fa fa-trash"></i></span>
              </div>
            </div>
            <div class="date">
              <span>{{ c.createDate }}</span>
            </div>
          </div>
        </div>
        <span class="to-end" @click="scrollToEnd()" v-show="!state.scrollEnd"><i class="fa fa-arrow-down"></i></span>
      </div>
      <div class="closed" v-if="calculateStatusCode === 'CAL_STS_0003'">
        <span>정산이 마감되었습니다.</span>
      </div>
      <div class="typing" v-else>
        <textarea class="form-control" placeholder="정산 관련 문의사항을 입력해주세요." ref="textareaRef" v-model="state.chat.content" @keyup.ctrl.enter="submit()"></textarea>
        <button class="btn btn-default" @click="submit()" title="메세지 발송">
          <i class="fa fa-paper-plane-o"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, onUnmounted, reactive, ref} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import store from "@/scripts/store";
import MemberPicture from "@/components/MemberPicture";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "ComponentCalculateChat";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, MemberPicture},
  mixins: [mixin],
  props: {
    projectSeq: String,
    openId: String,
    InvestEndDate: String,
    adminChatRead: String,
    makerChatRead: String,
    calculateStatusCode: String,
  },
  setup(props) {
    const component = new Component(() => {
      props && load();
    });

    const state = reactive({
      scrollEnd: true,
      adminChatRead: "",
      makerChatRead: "",
      chat: {
        loaded: false,
        more: false,
        list: [],
        pageable: null,
        total: 0,
        content: "",
      },
      args: {
        page: 0,
        size: 20,
        sort: "id,desc"
      },
    });

    const textareaRef = ref();
    const chatRef = ref();

    const load = (page, more) => {
      if (more) {
        state.chat.more = true;
        state.args.page += 1;
      } else {
        state.chat.loaded = false;
        !page && (state.args.page = 0);
      }

      http.get(`/api/maker/project/reward/${props.projectSeq}/calculate/chats`, state.args).then(({data}) => {
        if (store.state.account.memberAuth.includes("ADMIN") && props.adminChatRead !== "Y") {
          updateFlag("Y", "admin");
        } else if (store.state.account.memberSeq === props.openId && props.makerChatRead !== "Y") {
          updateFlag("Y", "maker");
        }

        state.chat.loaded = true;
        state.chat.more = false;
        state.chat.pageable = data.body.pageable;
        state.chat.total = data.body.total;

        const oldHeight = chatRef?.value?.scrollHeight;

        if (more || page) {
          for (let i1 in state.chat.list) {
            data.body.content = data.body.content.filter(i2 => state.chat.list[i1].id !== i2.id);
          }
          state.chat.list = data.body.content.reverse().concat(state.chat.list);
        } else {
          state.chat.list = data.body.content.reverse();
        }

        if (!more && !page) {
          return scrollToEnd();
        } else if (more) {
          nextTick(() => {
            chatRef?.value?.scrollTo({
              top: chatRef?.value?.scrollHeight - oldHeight,
              left: 0
            });
          });
        }
      });
    };

    const scrollToEnd = () => {
      nextTick(() => {
        const rect = chatRef?.value?.getBoundingClientRect();
        chatRef?.value?.scrollTo({
          top: rect.y + chatRef?.value?.scrollHeight,
          left: 0,
        });
      });
    };

    const onScroll = () => {
      nextTick(() => {
        if (chatRef?.value?.scrollTop + chatRef?.value?.offsetHeight + 30 >= chatRef?.value?.scrollHeight) {
          return state.scrollEnd = true;
        }

        state.scrollEnd = false;
      });
    };

    const submit = () => {
      if (!state.chat.content?.trim()) {
        return store.commit("setSwingMessage", "내용을 입력해주세요.");
      }

      const args = {
        content: state.chat.content?.trim()
      };

      state.chat.content = "";

      http.post(`/api/maker/project/reward/${props.projectSeq}/calculate/chats`, args).then(() => {
        if (store.state.account.memberSeq?.toString() === props.openId) {
          updateFlag("N", "admin");
        } else if (store.state.account.memberAuth.includes("ADMIN")) {
          updateFlag("N", "maker");
        }

        load();
      });
    };

    const remove = (id) => {
      store.commit("confirm", {
        message: "선택한 메세지를 삭제하시겠습니까?",
        subMessage: "삭제된 메세지는 복구가 불가합니다.",
        allowTxt: "삭제",
        allow() {
          for (let i in state.chat.list) {
            if (state.chat.list[i].id === id) {
              state.chat.list.splice(i, 1);
              break;
            }
          }

          http.delete(`/api/maker/project/reward/${props.projectSeq}/calculate/chats/${id}`).then(() => {
            load(true);
          });
        }
      });
    };

    const intervalLoad = setInterval(() => {
      const args = {
        page: 0,
        size: 20,
        sort: "id,desc",
      };

      http.get(`/api/maker/project/reward/${props.projectSeq}/calculate/chats`, args).then(({data}) => {
        for (let i1 in state.chat.list) {
          data.body.content = data.body.content.filter(i2 => {
            return state.chat.list[i1].id !== i2.id;
          });
        }

        if (data.body.content?.length) {
          if (store.state.account.memberAuth.includes("ADMIN")) {
            updateFlag("Y", "admin");
          } else if (store.state.account.memberSeq === props.openId) {
            updateFlag("Y", "maker");
          }
        }

        state.chat.list = state.chat.list.concat(data.body.content.reverse());
      });
    }, 10000);

    const updateFlag = (flag, auth) => {
      if (auth === "admin") {
        http.put(`/api/maker/project/reward/${props.projectSeq}/calculate/admin-chat-read`, {adminChatRead: flag});
      } else if (auth === "maker") {
        http.put(`/api/maker/project/reward/${props.projectSeq}/calculate/maker-chat-read`, {makerChatRead: flag});
      }
    };

    const open = () => {
      store.commit("openModal", {
        name: "Faq",
      });
    };

    onMounted(() => {
      chatRef?.value?.addEventListener("scroll", onScroll);

      if (new Date() <= new Date(props.investEndDate) || ["CAL_STS_0003"].includes(props.calculateStatusCode) || (props && !props.calculateStatusCode)) {
        return clearInterval(intervalLoad);
      }
    });

    onUnmounted(() => {
      clearInterval(intervalLoad);
    });

    return {component, state, textareaRef, chatRef, submit, load, remove, scrollToEnd, open};
  }
});
</script>

<style lang="scss" scoped>
.calculate-chat {
  height: 100%;
  position: relative;
  padding-top: $px33;

  .title {
    font-size: $px16;
    margin-bottom: $px10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .wrapper {
    border: $px1 solid $colorBorder;
    border-radius: $px4;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .comments {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 100%;
      padding: $px20 $px20 0 $px20;
      position: relative;
      max-height: $px456;

      > .btn {
        align-self: center;
        background-color: #eee;
        border-radius: $px100;
        font-size: $px12;
        white-space: nowrap;
        margin-bottom: $px20;
        transition: background-color 0.18s, color 0.18s;

        > i {
          padding-right: $px8;
        }

        &:hover {
          background-color: $colorDark;
          color: #fff;
        }
      }

      .comment {
        border-radius: $px10;
        font-size: $px14;
        position: relative;
        width: 100%;
        margin-bottom: $px20;

        > a, > span.img {
          display: inline-block;
          position: absolute;
          top: 0;
          line-height: 1;
        }

        > span.img {
          width: $px33;
          height: $px33;
          border: $px1 solid #eee;
          border-radius: 50%;
          background-color: $colorBackground;
        }

        .content {
          .top {
            display: flex;
            white-space: nowrap;
            align-items: flex-end;

            .text {
              background-color: $colorBackground;
              padding: $px16;
              max-width: 80%;
              margin-top: $px15;
              white-space: pre-line;
              border-radius: 0 $px4 $px4 $px4;

              .btn {
                background: #fff;
                border: 1px solid #f7f7f7;
                color: $colorSecondary;
                font-size: $px12;
                margin-top: $px15;
              }
            }

            .side {
              margin-left: $px4;

              > span {
                padding: $px4 $px4 0 $px4;
                color: #ccc;
                display: none;
                cursor: pointer;
              }
            }
          }

          .date {
            color: $colorSecondary;
            font-size: $px10;
          }

          &:hover {
            .side > span {
              display: inline-block;
            }
          }
        }

        &.auto {
          .content .top .text {
            > span {
              display: block;
              margin-bottom: $px10;
            }

            .btn {
              &:hover {
                background: $colorSecondary;
                color: #fff;
              }
            }
          }
        }

        &.maker {
          align-self: flex-end;

          > a {
            right: 0;
          }

          .content {
            padding-right: $px43;

            .top {
              flex-direction: row-reverse;

              .text {
                border-radius: $px4 0 $px4 $px4;
              }
            }

            .date {
              text-align: right;
            }
          }
        }

        &.admin {
          align-self: flex-start;

          > span.img {
            left: 0;
          }

          .content {
            padding-left: $px43;
          }
        }
      }

      .to-end {
        background-color: #fff;
        box-shadow: 0 0 $px4 $px4 $colorBackground;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        margin-left: auto;
        position: sticky;
        bottom: $px20;
        width: $px30;
        padding: $px15;

        > i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .closed {
      width: 100%;
      padding: $px20;
      text-align: center;
      border-radius: $px4;
      background: $colorBackground;
    }

    .typing {
      width: 100%;
      position: relative;

      > textarea {
        resize: none;
        padding-right: $px95;
        border-color: transparent;
        background: $colorBackground;
        border-radius: 0 0 $px4 $px4;

        &:focus {
          border: $px1 solid $colorPoint;
        }
      }

      > .btn {
        border: none;
        border-radius: 0;
        position: absolute;
        bottom: $px1;
        right: $px1;
        height: calc(100% - $px2);
        width: $px79;

        > i {
          font-size: $px20;
        }

        &:hover {
          background-color: $colorSecondary;
          color: #fff;
        }
      }
    }
  }
}
</style>