<template>
  <div class="manage-calculate">
    <template v-if="(new Date() > new Date(project.investEndDate) && state.status.calculateStatusCode)|| state.settlementFile.href || !state.loaded.status">
      <div class="table-responsive" :class="{skeleton: !state.loaded.status || !state.loaded.account}">
        <div class="title">
          <span>정산 내역</span>
        </div>
        <table class="table">
          <tr>
            <th>
              <span>모집 금액</span>
            </th>
            <td>
              <span>{{ $lib.getNumberFormat(state.account.totalAmt) || 0 }}원</span>
            </td>
            <th>
              <span>수수료 및 세금</span>
            </th>
            <td>
              <span>{{ $lib.getNumberFormat(state.account.subtotalCharge) || 0 }}원</span>
            </td>
            <th>
              <span>수수료 지원</span>
            </th>
            <td>
              <span>{{ $lib.getNumberFormat(state.account.supportCharge) || 0 }}원</span>
            </td>
          </tr>
          <tr>
            <th>
              <span>정산서</span>
            </th>
            <td>
              <a :href="state.settlementFile.href" target="_blank" rel="noopener noreferrer" class="link" title="정산서 다운로드" v-if="state.settlementFile.href">
                <i class="fa fa-download"></i>
                <span>{{ state.settlementFile.title }}</span>
              </a>
              <span v-else>(N/A)</span>
            </td>
            <th>
              <span>이메일</span>
            </th>
            <td>
              <span>{{ project.builderEmail }}</span>
            </td>
            <th>
              <span>송금 금액</span>
            </th>
            <td>
              <span class="amount">{{ $lib.getNumberFormat(state.account.accountAmt) || 0 }}원</span>
            </td>
          </tr>
          <template v-if="$store.state.account.memberAuth.includes('ADMIN')">
            <tr>
              <th>
                <span>수수료 지원 내역</span>
                <span class="desc">{{ getAccountType(state.account.accountType).title }}</span>
              </th>
              <td>
                <span v-if="!getAccountType(state.account.accountType).accountBenefitInvisible">{{ $lib.getNumberFormat(state.account.accountBenefit) || "(N/A)" }}</span>
                <span v-if="getAccountType(state.account.accountType).sign"> &nbsp;{{ getAccountType(state.account.accountType).sign }}</span>
              </td>
              <th>
                <span>파트너쉽</span>
              </th>
              <td>
                <span>{{ state.account.partnershipName || "(N/A)" }}</span>
              </td>
              <th>
                <span>포인트 결제 금액</span>
              </th>
              <td>
                <span>{{ $lib.getNumberFormat(state.account.pointAmt) }}원</span>
              </td>
            </tr>
            <tr>
              <th>
                <span>지원 금액 차감 전 수수료</span>
                <span class="desc">(공금가액 / 부가세 / 합계)</span>
              </th>
              <td colspan="5">
                <span>{{ getCharge() }}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span>지원 금액 차감 후 수수료</span>
                <span class="desc">(공금가액 / 부가세 / 합계)</span>
              </th>
              <td colspan="5">
              <span>
                {{ getCharge("afterBenefit") }}
              </span>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div class="wrapper row" :class="{skeleton: !state.loaded.status}">
        <div class="col-lg-6 left">
          <div class="title">
            <span>정산 정보</span>
            <button class="btn" @click="edit()" :class="state.accountEditable ? 'btn-point' : 'btn-default'" v-if="$store.state.account.memberAuth.includes('ADMIN') && state.status.calculateStatusCode !== 'CAL_STS_0001'">
              {{ state.accountEditable ? "저장" : "수정" }}하기
            </button>
          </div>
          <div class="agreement" v-if="state.status.calculateStatusCode === 'CAL_STS_0001' && !state.status.calculateAgreedDate">
            <div class="terms">
              <span>정산 동의는 철회하실 수 없으며 정산 내용에 이상이 있거나 궁금한 점이 생겼을 경우 화면 좌측의 채팅을 통해 담당자와 상담 후 정산을 진행해주시길 바랍니다.</span>
              <span>프로젝트 정산을 위하여 정산받으실 은행명, 계좌번호가 필요합니다.</span>
              <span>정보 제공 및 정산 내용에 모두 동의하신다면 아래 동의 버튼을 눌러 정산 과정을 진행해주세요.</span>
              <span>* 제공 및 내용에 동의하지 않으시면 정산을 받으실 수 없습니다.</span>
              <span>- 수집항목: 은행명, 계좌번호</span>
              <span>- 수집이용 목적: 프로젝트 정산용</span>
              <span>- 보유기간: 5년</span>
            </div>
            <span class="confirm">위 내용을 확인하였으며 해당 내용에 전부 동의하십니까?</span>
          </div>
          <ul v-else class="account tight">
            <template v-if="state.status.calculateAgreedDate">
              <li class="row">
                <div class="col-6">
                  <label :for="`${component.name}Bank`">
                    <span>은행</span>
                  </label>
                  <select :id="`${component.name}Bank`" class="form-control border-focus-point" v-model="state.status.bankCode"
                          :disabled="!$store.state.account.memberAuth.includes('ADMIN')? state.status.calculateStatusCode !== 'CAL_STS_0001' : (state.status.calculateStatusCode !== 'CAL_STS_0001' && !state.accountEditable)">
                    <option :value="null" hidden>은행선택</option>
                    <option :value="b.key" v-for="(b, idx) in $definitions.codes.arr.bankCodes" :key="idx">{{ b.value }}</option>
                  </select>
                </div>
                <div class="col-6">
                  <label :for="`${component.name}AccountHolder`">
                    <span>예금주</span>
                  </label>
                  <input :id="`${component.name}AccountHolder`" class="form-control border-focus-point" :value="project.builderName" readonly>
                </div>
              </li>
              <li>
                <label :for="`${component.name}Account`">
                  <span>계좌번호</span>
                </label>
                <Number :id="`${component.name}Account`" :value.sync="state.status.accountNum" :allowZero="true" :noComma="true" placeholder='숫자만 입력해주세요("-" 제외).'
                        :disabled="!$store.state.account.memberAuth.includes('ADMIN')? state.status.calculateStatusCode !== 'CAL_STS_0001' : (state.status.calculateStatusCode !== 'CAL_STS_0001' && !state.accountEditable)"/>
              </li>
            </template>
            <template v-if="state.files.length">
              <li class="file" v-for="(f, idx) in state.files" :key="idx">
                <label>
                  <span>{{ f.title }}</span>
                  <span class="badge complete"><i class="fa fa-check"></i></span>
                </label>
                <div class="row">
                  <div :class="['CAL_STS_0001','CAL_STS_0004', 'CAL_STS_0005'].includes(state.status.calculateStatusCode) ? 'col-10' : 'col-12'">
                    <button class="btn btn-default" @click="viewFile(f.href)" title="클릭하여 확인하기">
                      <span>{{ f.fileOriginName }}</span>
                    </button>
                  </div>
                  <div class="col-2" v-if="['CAL_STS_0001','CAL_STS_0004', 'CAL_STS_0005'].includes(state.status.calculateStatusCode)">
                    <label class="btn btn-default" title="정산 파일 수정하기">
                      <i class="fa fa-pencil-square-o"></i>
                      <input type="file" class="hide" @change="uploadFile($event, f.fileCode)" :accept="$definitions.limits.fileExtensions.allStr">
                    </label>
                  </div>
                </div>
              </li>
            </template>
            <li class="file" v-if="!state.files.some(f=>f.fileCode === 'biz_doc')">
              <label>
                <span>사업자등록증 등록하기</span>
                <span class="badge"><i class="fa fa-check"></i></span>
              </label>
              <label class="btn btn-default">
                <span>클릭하여 사업자등록증 등록</span>
                <input type="file" class="hide" @change="uploadFile($event, 'biz_doc')" :accept="$definitions.limits.fileExtensions.allStr"/>
              </label>
            </li>
            <li class="file" v-if="!state.files.some(f=>f.fileCode === 'bankbook')">
              <label>
                <span>통장 사본 등록하기</span>
                <span class="badge"><i class="fa fa-check"></i></span>
              </label>
              <label class="btn btn-default">
                <span>클릭하여 통장 사본 등록</span>
                <input type="file" class="hide" @change="uploadFile($event, 'bankbook')" :accept="$definitions.limits.fileExtensions.allStr"/>
              </label>
            </li>
            <li v-if="$store.state.account.memberAuth.includes('ADMIN')" class="memo">
              <label :for="`${component.name}Memo`">
                <span>PM 메모</span>
              </label>
              <div class="row">
                <div class="col-10">
                  <textarea @keyup.exact.enter.ctrl="submit()" placeholder="메모를 남겨주세요." class="form-control h-auto border-focus-point thin-scrollbar" :id="`${component.name}Memo`" v-model="state.status.pmNote"></textarea>
                </div>
                <div class="col-2">
                  <button class="btn btn-default h-100" title="메모 저장하기" @click="submit()">
                    <span>저장</span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="actions" v-if="state.status.calculateStatusCode">
            <template v-if="project.openId === $store.state.account.memberSeq">
              <button class="btn btn-point" @click="request()" v-if="!state.status.calculateAgreedDate && state.status.calculateStatusCode === 'CAL_STS_0001'">네, 동의합니다.</button>
              <button class="btn btn-point" @click="requestConfirm()" v-else-if="state.status.calculateStatusCode === 'CAL_STS_0001'">정산 요청</button>
              <button class="btn bg-light" disabled v-else>{{ state.status.calculateStatusCode === "CAL_STS_0003" ? "정산이 마감되었습니다." : "정산이 진행중입니다." }}</button>
            </template>
            <template v-else-if="$store.state.account.memberAuth.includes('ADMIN')">
              <div class="wrapper" v-if="state.status.calculateStatusCode === 'CAL_STS_0001'">
                <button class="btn bg-light" disabled>진행자가 정산에 동의하기 전입니다.</button>
                <button class="btn btn-point mt-2" @click="!state.status.calculateAgreedDate ? request() : requestConfirm()">관리자가 진행하기</button>
              </div>
              <button class="btn btn-point" @click="requestComplete()" v-else-if="state.status.calculateStatusCode === 'CAL_STS_0004'">정산 확정 및 최종 요청</button>
              <button class="btn btn-point" @click="complete()" v-else-if="state.status.calculateStatusCode === 'CAL_STS_0005'">정산 마감</button>
              <button class="btn bg-light" disabled v-else>{{ state.status.calculateStatusCode === "CAL_STS_0003" ? "정산이 마감되었습니다." : "진행자가 정산 요청을 하기 전입니다." }}</button>
            </template>
          </div>
        </div>
        <div class="col-lg-6">
          <CalculateChat
              :projectSeq="$route.params.projectSeq"
              :openId="project.openId"
              :investEndDate="project.investEndDate"
              :adminChatRead="state.status.adminChatRead"
              :makerChatRead="state.status.makerChatRead"
              :calculateStatusCode="state.status.calculateStatusCode"
              v-if="project && state.loaded.status"
          />
        </div>
      </div>
    </template>
    <div class="no-data" v-else-if="new Date() > new Date(project.investEndDate) && project.fundingType === 'A' && project.successFailFlag !== 'S'">
      <span>해당 사항이 없는 프로젝트 입니다.</span>
    </div>
    <div class="no-data" v-else>
      <span>데이터가 없습니다.</span>
    </div>
    <div class="notice bottom" :class="{skeleton: !state.loaded.status || !state.loaded.account}">
      <span class="emphasis">후원형 크라우드펀딩을 통해 자금을 모집한 진행자는 관계법령에 따라 세금의 신고 및 납부 의무를 가집니다. 진행자 유형(개인/개인사업자/법인사업자)에 따라 적용되는 관계법령이 다르기 때문에, 이와 관련된 세금 신고 방법은 국세청, 관할세무서, 세무대리인 등을 통해 안내 받으시기 바랍니다.</span>
      <span>무조건 리워드(Keep it all) : 모집 금액의 11% (플랫폼 수수료 7% + 결제 PG수수료 3% + 플랫폼 및 결제 수수료의 부가세 10%)</span>
      <span>성공해야 리워드(All or nothing) : 모집 금액의 8.8% (플랫폼 수수료 5% + 결제 PG수수료 3% + 플랫폼 및 결제 수수료의 부가세 10%)</span>
      <span class="emphasis">성공해야 리워드는 펀딩 종료 후 예약 결제를 총 5차례 진행하며, 후원자분들이 카드를 분실했거나 카드와 연결되어 있는 계좌에 잔액이 부족한 경우
        최종 결제가 되지  않아 모집 금액과 정산 금액이 다를 수 있습니다.</span>
      <span>정산 시 플랫폼 이용 수수료에 대한 전자세금계산서를 회원가입 시 등록한 이메일로 발송해 드립니다.</span>
      <span class="emphasis">펀딩 금액에 대한 회계 처리는 상품 및 서비스에 대한 ‘일반현금매출’로 처리하시면 됩니다.</span>
      <span>정산서 상세 내역은 상단의 정산관련서류 - 정산서 버튼을 누르면 확인하실 수 있습니다.</span>
      <span>후원자 정보는 정산서 파일의 두 번째 탭 ‘후원자 목록’에서 확인하실 수 있습니다.</span>
      <span class="emphasis">후원자 정보는 개인정보보호정책에 준하여 리워드 제공 관리 외 목적으로의 사용을 금합니다.</span>
      <span>리워드 제공은 크라우드펀딩 페이지에 안내한 일정대로 진행해 주시기 바라며, 만약 예정된 일정보다 제공이 연기되거나 다른 리워드로 대체가 필요하다면 후원자분들께 별도로 안내해 주시기 바랍니다.</span>
      <span class="emphasis">리워드 배송에 관한 법적 책임은 프로젝트 진행자에게 있습니다.</span>
      <span>리워드 제공이 완료되면 로그인 > 마이페이지 > 진행 프로젝트 > 관리(톱니바퀴 아이콘) > 후원자 관리 > 리워드 제공 관리에서 <span class="img" @click="openPhoto()">제공 처리</span>를 진행해 주세요.</span>
    </div>
  </div>
</template>

<script>import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import http from "@/scripts/http";
import Number from "@/components/Number";
import MemberPicture from "@/components/MemberPicture";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import NoData from "@/components/NoData";
import CalculateChat from "@/components/CalculateChat";
import definitions from "@/scripts/definitions";
import $definitions from "@/scripts/definitions";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pageManageCalculate";
  this.initialize = initialize;
}

export default defineComponent({
  computed: {
    $definitions() {
      return $definitions;
    }
  },
  components: {CalculateChat, NoData, Anchor, MemberPicture, Number},
  mixins: [mixin],
  props: {
    project: Object,
  },
  setup() {
    const component = new Component(async () => {
      await http.post(`/api/maker/project/${projectType}/${projectSeq}/calculate`);
      load();
    });

    const state = reactive({
      accountEditable: false,
      loaded: {
        status: false,
        account: false,
      },
      settlementFile: {
        fileCode: "",
        title: "",
        href: "",
      },
      status: {
        calculateStatusCode: "",
        adminChatRead: "",
        makerChatRead: "",
        calculateAgreedDate: "",
        accountNum: "",
        bankCode: "",
        pmNote: "",
        calculateCodeList: [],
      },
      account: {
        accountAmt: 0,
        accountType: 0,
        accountBenefit: 0,
        partnershipNames: "",
        subtotalCharge: 0,
        supportCharge: 0,
        surtax: 0,
        pointAmt: 0,
        totalAmt: 0,
      },
      files: [],
    });

    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;

    const load = () => {
      state.loaded.status = false;
      state.loaded.account = false;

      http.get(`/api/maker/project/${projectType}/${projectSeq}/calculate`).then(({data}) => {
        state.loaded.status = true;
        state.status = data.body;

        state.files = [];

        data.body.bizDocFile?.length && state.files.push({
          fileCode: "biz_doc",
          fileOriginName: data.body.bizDocFile[0].fileOrigName,
          title: "사업자등록증(개인신분증)",
          href: data.body.bizDocFile[0].filePath + data.body.bizDocFile[0].fileSaveName
        });

        data.body.bankbookFile?.length && state.files.push({
          fileCode: "bankbook",
          fileOriginName: data.body.bankbookFile[0].fileOrigName,
          title: "통장 사본",
          href: data.body.bankbookFile[0].filePath + data.body.bankbookFile[0].fileSaveName
        });

        data.body.settlementFile?.length && (state.settlementFile = {
          fileCode: "settlementFile",
          title: "정산서 다운로드",
          href: data.body.settlementFile[0].filePath + data.body.settlementFile[0].fileSaveName
        });
      });

      http.get(`/api/maker/project/${projectType}/${projectSeq}/calculate/statement`).then(({data}) => {
        state.loaded.account = true;

        if (!data.body || !Object.values(data?.body)?.length) {
          return;
        }

        state.account = data.body;
      });
    };

    const getAccountType = (type) => {
      const accountType = {
        title: "",
        sign: "",
        accountBenefitInvisible: false,
      };

      switch (type) {
        case 0:
          accountType.title = "없음";
          accountType.sign = "";
          break;
        case 1:
          accountType.title = "전액 지원";
          accountType.sign = "%";
          break;
        case 2:
          accountType.title = "일정 금액 지원";
          accountType.sign = "원";
          break;
        case 3:
          accountType.title = "정률 지원";
          accountType.sign = "%";
          break;
        case 4:
          accountType.title = "플랫폼 이용 수수료 면제(단, 플랫폼 이용 수수료 부가세 10%는 청구)";
          accountType.sign = "%";
          break;
        case 5:
          accountType.title = "플랫폼 이용 수수료 면제(플랫폼 이용 수수료 부가세 포함 면제)";
          accountType.sign = "%";
          break;
        case 6:
          accountType.title = "플랫폼 이용 수수료 및 PG 결제 수수료 면제 (단, 전체 수수료 부가세 10%는 청구)";
          accountType.sign = "%";
          break;
        case 7:
          accountType.title = "수수료 및 세금 소계의 50% 면제";
          accountType.sign = "%";
          break;
        case 8:
          accountType.title = "플랫폼 이용 수수료율 인하";
          accountType.sign = "%";
          break;
        case 9:
          accountType.title = "플랫폼 이용 수수료(부가세 포함) 지원";
          accountType.sign = "%";
          break;
        case 10:
          accountType.title = "OMC 자체 지원";
          accountType.sign = "플랫폼 이용 수수료 전액 및 결제 수수료 일부 지원";
          accountType.accountBenefitInvisible = true;
          break;
        case 11:
          accountType.title = "별도 지원";
          accountType.sign = "PM 메모 확인";
          accountType.accountBenefitInvisible = true;
          break;
        default:
          return accountType;
      }

      return accountType;
    };

    const getCharge = (type) => {
      if (type === "afterBenefit") {
        const afterBenefit = state.account.subtotalCharge - state.account.supportCharge;
        const supply = Math.floor(afterBenefit / 1.1);

        return `${lib.getNumberFormat(supply)}원 / ${lib.getNumberFormat(afterBenefit - supply)}원 / ${lib.getNumberFormat(afterBenefit)}원`;
      }

      return `${lib.getNumberFormat(state.account.subtotalCharge - state.account.surtax)}원 / ${lib.getNumberFormat(state.account.surtax)}원 / ${lib.getNumberFormat(state.account.subtotalCharge)}원`;
    };

    const update = (codeNum, skipMessage) => {
      const calculateStatusCode = state.status.calculateCodeList[state.status.calculateCodeList?.findIndex(c => c.codeId.includes(codeNum?.toString()))];

      http.put(`/api/maker/project/reward/${projectSeq}/calculate/status`, {calculateStatusCode: calculateStatusCode.codeId}).then(() => {
        !skipMessage && store.commit("setSwingMessage", `${calculateStatusCode?.codeIdName} 요청이 처리되었습니다.`);
        load();
      });
    };

    const request = () => {
      store.commit("confirm", {
        message: store.state.account.memberAuth.includes("ADMIN") ? "관리자 계정으로 절차를 진행 중입니다. 해당 금액으로 정산을 진행하시겠습니까?" : "해당 금액으로 정산이 진행되는 것에 동의하십니까?",
        subMessage: store.state.account.memberAuth.includes("ADMIN") ? "꼭 진행자의 동의를 얻은 후 진행해주세요." : "동의는 철회하실 수 없습니다.",
        allowTxt: "동의함",
        disallowTxt: "동의하지 않음",
        allow() {
          http.put(`/api/maker/project/reward/${projectSeq}/calculate/agreed`).then(() => {
            load();
          });
        },
        disallow() {
          store.commit("setSwingMessage", "정산 과정은 해당 내용에 동의 후 진행됩니다.");
        }
      });
    };

    const requestConfirm = () => {
      setBankInfo(() => update(4));
    };

    const edit = () => {
      if (!state.accountEditable) {
        return state.accountEditable = true;
      } else {
        setBankInfo();
        state.accountEditable = false;
      }
    };

    const setBankInfo = (callback) => {
      if (!state.status.bankCode) {
        return store.commit("setSwingMessage", "은행을 선택해주세요");
      } else if (!state.status.accountNum) {
        return store.commit("setSwingMessage", "계좌 번호를 입력해주세요");
      } else if (state.files.findIndex(f => f.fileCode === "biz_doc") < 0) {
        return store.commit("setSwingMessage", "사업자 등록증을 등록해주세요");
      } else if (state.files.findIndex(f => f.fileCode === "bankbook") < 0) {
        return store.commit("setSwingMessage", "통장 사본을 등록해주세요");
      }

      const args = {
        accountNum: state.status.accountNum,
        bankCode: state.status.bankCode,
      };

      store.commit("confirm", {
        message: `${definitions.codes?.arr.bankCodes[definitions.codes?.arr?.bankCodes?.findIndex(c => c.key === args.bankCode)]?.value} ${args.accountNum}`,
        subMessage: store.state.account.memberAuth.includes("ADMIN") ? "위 정보로 은행정보를 저장하시겠습니까?" : "정산 요청 후에는 계좌 변경이 불가능 하오니 다시 한 번 확인해주시길 바랍니다.",
        allow() {
          http.put(`/api/maker/project/${projectType}/${projectSeq}/calculate/account`, args).then(() => {
            (callback && typeof callback === "function") && callback();
          });
        }
      });
    };

    const requestComplete = () => {
      if (state.files.findIndex(f => f.fileCode === "biz_doc") < 0) {
        return store.commit("setSwingMessage", "사업자 등록증을 등록해주세요");
      } else if (state.files.findIndex(f => f.fileCode === "bankbook") < 0) {
        return store.commit("setSwingMessage", "통장 사본을 등록해주세요");
      }

      const message = state.accountEditable ? "저장되지 않은 정산 정보가 있습니다. 기존의 정산 정보로 최종 요청 하시겠습니까?" : "해당 내용으로 정산을 최종 요청하시겠습니까?";

      store.commit("confirm", {
        message: message,
        subMessage: state.accountEditable && "정산 정보가 변경되었다면 저장하기 버튼을 눌러 저장 후 다시 시도해주세요",
        allowTxt: "요청",
        allow() {
          update(5);
        },
      });
    };

    const complete = () => {
      store.commit("confirm", {
        message: "프로젝트 정산을 마감하시겠어요?",
        subMessage: "마감은 꼭 모든 정산 과정이 끝난 뒤 정산 담당자가 진행하여 주시길 바랍니다.",
        allowTxt: "마감",
        allow() {
          update(3);
        }
      });
    };

    const uploadFile = (e, fileCode) => {
      if (!e.target.files.length) {
        return store.commit("setSwingMessage", "파일 업로드에 실패했습니다.");
      } else if (!store.getters.isAllowedExtension(e.target, "all")) {
        return;
      }

      const formData = new FormData();

      formData.append("files", e.target.files[0]);
      formData.append("fileCode", fileCode);

      http.post(`/api/maker/project/${projectType}/${projectSeq}/calculate/upload`, formData).then(({data}) => {
        store.commit("setSwingMessage", "파일 업로드를 완료하였습니다.");
        e.target.value = "";

        if (state.status.calculateStatusCode !== "CAL_STS_0001" && !store.state.account.memberAuth.includes("ADMIN")) {
          update(1, true);
          window.alert("내용이 변경되었습니다. 정산을 다시 요청해주세요.");
        }

        const file = data.body?.length && {
          fileCode: data.body[0].fileCode,
          fileOriginName: data.body[0].fileOrigName,
          title: fileCode === "bankbook" ? "통장 사본" : "사업자등록증 (개인 신분증)",
          href: data.body[0].filePath + data.body[0].fileSaveName
        };

        if (state.files.findIndex(f => f.fileCode === fileCode) >= 0) {
          state.files.splice(state.files.findIndex(f => f.fileCode === fileCode), 1, file);
        } else {
          state.files.push(file);
        }
      }).catch(() => {
        e.target.value = "";
      });
    };

    const openPhoto = () => {
      store.commit("openModal", {
        name: "ReviewPhotoSlider",
        params: {
          photos: [{
            filePath: `/assets/img/page.manage.calculate.reward.delivery.guide.popup.jpg`,
          }]
        },
      });
    };

    const viewFile = (url) => {
      const downloadExtensions = [...definitions.limits.fileExtensions.img.filter(ex => ex !== "heic"), "pdf"];

      if (!downloadExtensions.includes(url.split(".")[url.split(".").length - 1])) {
        return location.href = url;
      }

      window.open(`${url}`, "_blank", "width=1000,height=900");
    };

    const submit = () => {
      http.put(`/api/admin/project/${projectType}/${projectSeq}/calculate/memo`, {pmNote: state.status.pmNote}).then(() => {
        load();
      });
    };

    return {component, state, load, getAccountType, getCharge, request, requestConfirm, requestComplete, complete, uploadFile, openPhoto, viewFile, submit, edit};
  }
});
</script>

<style lang="scss" scoped>
.manage-calculate {
  .title {
    display: block;
    font-size: $px16;
    margin-bottom: $px10;
  }

  .form-control, .btn {
    height: $formHeight;
    width: 100%;
  }

  .table-responsive {
    margin-bottom: $px50;

    .amount {
      color: $colorPoint;
      font-weight: 600;
    }

    .table {
      table-layout: fixed;

      tr {
        th, td {
          padding: $px10;
          text-align: center;
          vertical-align: middle;
        }

        th {
          background: #f7f7f7;

          .desc {
            font-weight: 400;
            font-size: $px11;
            color: #aaa;
            display: block;
          }
        }

        td {
          white-space: break-spaces;

          .link {
            i {
              vertical-align: sub;
              margin-right: $px5;
            }

            span {
              text-decoration: underline;
              vertical-align: middle;
            }
          }
        }
      }
    }

    &.skeleton {
      .title span {
        @include skeleton;
      }

      table tr {
        td, th {
          span, a, .btn {
            @include skeleton;
          }
        }
      }
    }
  }

  > .wrapper {
    font-size: $px14;

    > .left {
      > .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .btn {
          font-size: $px12;
          border-radius: $px100;
          padding: $px4 $px12;
          width: auto;
          height: auto;
        }
      }

      .agreement {
        .btn {
          margin-top: $px10;
        }

        .terms {
          background-color: $colorBackground;
          border-radius: $px4;
          font-size: $px12;
          padding: $px20;
          margin-top: $px20;
          width: 100%;

          > span {
            display: block;
            margin-bottom: $px4;
          }
        }

        .confirm {
          display: block;
          font-size: $px16;
          margin-top: $px10;
        }
      }

      .account li {
        &.memo {
          .row {
            .col-10 {
              padding-right: $px5;

              input {
                font-size: $px13;
              }
            }

            .col-2 {
              padding-left: 0;

              .btn {
                white-space: nowrap;
                font-size: $px12;
              }
            }
          }
        }

        &.file {
          .badge {
            background-color: $colorSecondary;
            border-radius: 50%;
            color: #fff;
            display: inline-block;
            width: $px16;
            height: $px16;
            margin-left: $px5;
            position: relative;
            padding: 0;
            vertical-align: middle;

            > i {
              margin: 0;
              padding: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: $px10;
              transform: translate(-50%, -50%);
            }

            &.complete {
              background-color: $colorPoint;
            }
          }

          .btn {
            letter-spacing: 0;
            color: #8898aa;
            font-size: $px13;

            i {
              margin: 0;
            }
          }

          .row {
            .col-10 {
              padding-right: $px5;
            }

            .col-2 {
              padding-left: 0;
            }

            &:not(:last-child) {
              margin-bottom: $px5;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: $px20;
        }
      }
    }

    .actions {
      margin-top: $px20;

      .btn {
        height: $px61;
      }
    }

    &.skeleton {
      .title span, .title > .btn {
        @include skeleton;
      }

      > .left {
        .agreement {
          .terms {
            @include skeleton;
          }

          .confirm {
            @include skeleton;
          }
        }

        .account li {
          label, .form-control, span, .btn {
            @include skeleton;
          }
        }

        .actions .btn {
          @include skeleton;
        }
      }
    }
  }

  .notice {
    background-color: $colorBackground;
    border-radius: $px4;
    border: $px1 solid #eee;
    font-size: $px13;
    padding: $px20;
    margin-top: $px25;
    width: 100%;

    > span {
      display: flex;
      margin-bottom: $px5;

      .img {
        cursor: pointer;
        color: $colorPoint;
        padding: 0 $px5;
        text-decoration: underline;
        position: relative;
      }

      &:not(.emphasis):before {
        content: "-";
        display: inline-block;
        margin-right: $px4;
      }

      &.emphasis {
        font-weight: 600;

        &:before {
          content: "*";
          display: inline-block;
          font-weight: 600;
          margin-right: $px4;
        }
      }
    }

    &.skeleton {
      @include skeleton;

      span, span.img {
        @include skeleton;
      }
    }
  }

  .no-data {
    font-size: $px16;
    text-align: center;
    padding: $px50 0;
  }

  @media (max-width: 767px) {
    > .row {
      flex-direction: column;

      > .col {
        &:not(:last-child) {
          margin-bottom: $px15;
        }
      }
    }
  }
}
</style>